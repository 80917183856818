import React, { useState, useEffect } from 'react';
import { NextPage, GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { useExperiment } from '@optimizely/react-sdk';

import { Props } from 'types/index';
import TopicName from 'components/TopicName';
import { Col, Row } from 'components/TypedCircuit';
import TypedSections from 'components/TypedSections';
import { TypeContainer } from 'components/styledComponents';
import { getIndexStaticProps } from 'services/staticGeneration/index';
import { showSmartSearch } from 'services/common';
import PromoModal from 'components/AiSearchEntryPoint/components/PromoModal';
import {
  SMART_SEARCH_EXPERIMENT_KEY,
  SMART_SEARCH_IT_EXPERIMENT_KEY,
} from 'constants/common';

const Page: NextPage<Props> = ({ sectionTree, articles, sectionTypeTree }) => {
  const router = useRouter();
  const [shouldShowEntryPoint, setShouldShowEntryPoint] = useState(false);

  const [variation, clientReady] = useExperiment(
    router.locale === 'it-IT'
      ? SMART_SEARCH_IT_EXPERIMENT_KEY
      : SMART_SEARCH_EXPERIMENT_KEY,
    {
      autoUpdate: true,
    },
  );

  useEffect(() => {
    if (showSmartSearch({ locale: router.locale, variation, clientReady })) {
      setShouldShowEntryPoint(true);
    } else {
      setShouldShowEntryPoint(false);
    }
  }, [clientReady, router.locale, variation]);

  return (
    <>
      {shouldShowEntryPoint && <PromoModal />}
      <Col span="12" skip="0">
        {Object.keys(sectionTypeTree).map((topic) => (
          <Row key={topic}>
            {TopicName(topic)}
            <TypeContainer>
              <TypedSections
                sectionTree={sectionTree}
                articles={articles}
                topic={topic}
                sectionTypeTree={sectionTypeTree}
              />
            </TypeContainer>
          </Row>
        ))}
      </Col>
    </>
  );
};

// To bring back once we enable en-GB
export const getStaticProps: GetStaticProps = getIndexStaticProps;

export default Page;
